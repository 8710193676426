'use client';

import { navLinks } from '@/components/Header/helpers';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useLockBodyScroll, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';
import s from './MobileMenu.module.scss';

interface Props {
  className: string;
}

export const MobileMenu: FC<Props> = ({ className }) => {
  const currentRoute = usePathname();
  const [open, toggleOpen] = useToggle(false);

  useLockBodyScroll(open);
  const handlers = useSwipeable({
    onSwipedDown: () => {
      toggleOpen(false);
    },
  });

  return (
    <div
      className={clsx(twMerge(className), {
        [s.open]: open,
      })}
    >
      <div className="p-4" onClick={toggleOpen}>
        <svg className={s.burger}>
          <line x1={0} x2={24} y1={4} y2={4} />
          <line x1={0} x2={24} y1={12} y2={12} />
          <line x1={0} x2={24} y1={20} y2={20} />
        </svg>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            {...handlers}
            className={`w-full h-full bg-neutral-950 border-t-[1px] border-neutral-800 fixed top-[86px] left-0 flex flex-col px-6 pt-2 pb-6 gap-4 rounded-t-2xl shadow-2xl font-chakra`}
            initial={{
              transform: 'translateY(100%)',
            }}
            animate={{
              transform: 'translateY(0)',
              transition: {
                ease: [0.46, 0.83, 0.52, 0.96],
                duration: 0.5,
              },
            }}
            exit={{
              transform: 'translateY(100%)',
              transition: {
                ease: [0.46, 0.83, 0.52, 0.96],
                duration: 0.3,
              },
            }}
          >
            <div className="h-1 flex-1 max-h-1 mx-16 my-2 bg-neutral-500 rounded-xl" />
            {navLinks().map(({ text, href, disabled, show }, ix) => {
              if (show) {
                if (disabled) {
                  return (
                    <div
                      key={`${text}-${ix}`}
                      className={`text-neutral-100 font-medium opacity-40 pointer-events-none`}
                    >
                      {text}
                    </div>
                  );
                }

                return (
                  <Link
                    key={text}
                    href={href}
                    className={`flex items-center w-full  text-neutral-100 font-medium text-lg p-4 rounded-md ${
                      currentRoute === href ? 'text-primary-500 bg-neutral-900' : ''
                    }`}
                    onClick={toggleOpen}
                  >
                    {text}
                  </Link>
                );
              }

              return null;
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
